import { StatusCodes } from 'http-status-codes'

import { useDevicesStore } from '@/stores/devices'

// @TODO: This won't work anymore, needs to be fixed!
import ApiClient from '@/clients/apiClient'

import { DEVICE_COMMAND_RELAY_ENDPOINT } from '@/constants/ApiEndpoints'
import { DOCKER_API_VERSION } from '@/constants/Docker'
import { AppError } from '@/utils/appError'

/**
 * Error request handler.
 *
 * @param {Number} statusCode - The status code of the error.
 * @param {Object} error - The error object, optional.
 * @throws {AppError} The corresponding error.
 */
const handleAgentError = (statusCode, error = null) => {
  switch (statusCode) {
    case StatusCodes.BAD_GATEWAY:
    case StatusCodes.SERVICE_UNAVAILABLE:
      throw new AppError(
        StatusCodes.SERVICE_UNAVAILABLE,
        'Failed to connect to device.',
        'Device unavailable',
        'Looks like your device is offline. Please check the connection and try again.'
      )
    default:
      throw new AppError(
        StatusCodes.INTERNAL_SERVER_ERROR,
        'Failed to retrieve device data, an unexpected error occurred.',
        'Service unavailable',
        'Device data cannot be retrieved at the time. Please try again later.',
        error || null
      )
  }
}

/**
 * Fetches the details of a device.
 *
 * @param {string} deviceId - The ID of the device.
 * @returns {Promise<Object>} A promise that resolves with the device details.
 */
const getDeviceDetails = async (deviceId) => {
  const devicesStore = useDevicesStore()
  return await devicesStore.getDeviceById(deviceId)
}

/**
 * Constructs the base payload for a request.
 *
 * @param {Object} device - The device object.
 * @returns {Array} The base payload for a request.
 */
const getRequestBasePayload = (device) => {
  return [
    {
      postmanId: device.postman_id,
      agents: []
    }
  ]
}

export const getDeviceContainerExecId = async (deviceId, containerId) => {
  const device = await getDeviceDetails(deviceId)
  let requestPayload = getRequestBasePayload(device)

  // Construct request payload.
  requestPayload[0].agents.push({
    ip: device.ip,
    method: 'POST',
    path: {
      apiVersion: DOCKER_API_VERSION,
      resource: 'containers',
      resourceId: containerId,
      action: 'exec'
    },
    payload: {
      AttachStdin: true,
      AttachStdout: true,
      AttachStderr: true,
      Tty: true,
      Cmd: ['/bin/sh']
    }
  })

  // Request exec ID from the MoT API using the Docker relay endpoint.
  try {
    const response = await ApiClient.post(DEVICE_COMMAND_RELAY_ENDPOINT, requestPayload)
    const agentResponse = response.data[0].agents[0]

    if (agentResponse?.statusCode !== StatusCodes.CREATED) {
      return handleAgentError(agentResponse?.statusCode)
    }

    return response.data[0].agents[0].response.Id
  } catch (error) {
    if (error instanceof AppError) throw error
    handleAgentError(error?.status || StatusCodes.INTERNAL_SERVER_ERROR, error)
  }
}

/**
 * Fetch device containers
 *
 * @param {String} deviceId – The device ID.
 * @returns {Promise<Array>} A promise that resolves with the list of containers.
 */
export const getDeviceContainers = async (deviceId) => {
  const device = await getDeviceDetails(deviceId)
  let requestPayload = getRequestBasePayload(device)

  // Construct request payload.
  requestPayload[0].agents.push({
    ip: device.ip,
    method: 'GET',
    path: {
      apiVersion: DOCKER_API_VERSION,
      resource: 'containers/json',
      queryParams: {
        all: ['true']
      }
    }
  })

  // Fetch container data from the MoT API using the Docker relay endpoint.
  try {
    const response = await ApiClient.post(DEVICE_COMMAND_RELAY_ENDPOINT, requestPayload)
    const agentResponse = response.data[0].agents[0]

    if (agentResponse?.statusCode !== StatusCodes.OK) {
      return handleAgentError(agentResponse?.statusCode)
    }

    return response.data[0].agents[0].response
  } catch (error) {
    if (error instanceof AppError) throw error
    handleAgentError(error?.status || StatusCodes.INTERNAL_SERVER_ERROR, error)
  }
}

/**
 * Get the stats for the specified containers on the device.
 *
 * @param {string} deviceId – The device ID.
 * @param {Array} containerIds – The container IDs to fetch stats for.
 * @returns {Promise<Array>} A promise that resolves with the container stats.
 * @throws {AppError} If the request fails.
 */
export const getDeviceContainerStats = async (deviceId, containerIds) => {
  const device = await getDeviceDetails(deviceId)
  let requestPayload = getRequestBasePayload(device)

  // Add agents to the request payload, one for each container as the Docker API does not support fetching stats for
  // multiple containers in a single request. The MoT API does support batching by sending multiple agents in a single
  // request, even if each agent entry is for the same device.
  containerIds.forEach((containerId) => {
    requestPayload[0].agents.push({
      ip: device.ip,
      method: 'GET',
      path: {
        apiVersion: DOCKER_API_VERSION,
        resource: 'containers',
        resourceId: containerId,
        action: 'stats',
        queryParams: {
          stream: ['false']
        }
      }
    })
  })

  // Fetch container data from the MoT API using the Docker relay endpoint.
  try {
    const response = await ApiClient.post(DEVICE_COMMAND_RELAY_ENDPOINT, requestPayload)

    // Aggregate the stats.
    // @TODO: Theoretically, any agent entry in this response could have failed, but we're assuming that if one fails,
    // all fail. This needs to be revisited if we ever need to handle partial failures.
    const containerStats = response.data[0].agents.map((agent) => {
      return agent.response
    })

    // Until mesh-of-things/api/issues/94 is resolved, manually process the response to inject the containerId back
    // based on the order of the request. Docker seems to occasionally return an ID as part of the stats, but as it's
    // not documented, we can't rely on it.
    containerStats.forEach((container, index) => {
      container.containerId = containerIds[index]
    })

    return containerStats
  } catch (error) {
    if (error instanceof AppError) throw error
    handleAgentError(error?.status || StatusCodes.INTERNAL_SERVER_ERROR, error)
  }
}
