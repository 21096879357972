import logger from '../logger'
import { useDevicesStore } from '@/stores/devices'
import { useDeviceContainersStore } from '@/stores/deviceContainers'

import { WEBSOCKET_ACTIONS, WEBSOCKET_STREAMS } from '@/constants/WebSocket'
import { DOCKER_API_VERSION } from '@/constants/Docker'

/**
 * Fetches the details of a device.
 *
 * @param {string} deviceId - The ID of the device.
 * @returns {Promise<Object>} A promise that resolves with the device details.
 */
const getDeviceDetails = async (deviceId) => {
  const devicesStore = useDevicesStore()
  return await devicesStore.getDeviceById(deviceId)
}

/**
 * Constructs the base payload for a request.
 *
 * @param {String} streamType - The stream type to subscribe to.
 * @param {String} postmanId - The postman ID of the device(s), optional.
 * @returns {Array} The base payload for a request.
 */
const getRequestBasePayload = (streamType, postmanId = false) => {
  const basePayload = {
    action: WEBSOCKET_ACTIONS.SUBSCRIBE,
    stream: streamType,
    payload: []
  }

  if (postmanId) {
    basePayload.payload.push({ postmanId, agents: [] })
  }

  return basePayload
}

export const requestLogStreaming = async (socket, deviceId, containerId) => {
  const device = await getDeviceDetails(deviceId)

  // Construct request payload.
  let requestPayload = getRequestBasePayload(WEBSOCKET_STREAMS.RELAY, device.postman_id)
  requestPayload.payload[0].agents.push({
    ip: device.ip,
    method: 'GET',
    path: {
      apiVersion: DOCKER_API_VERSION,
      resource: 'containers',
      resourceId: containerId,
      action: 'logs',
      queryParams: {
        follow: ['true'],
        stdout: ['true'],
        stderr: ['true'],
        timestamps: ['true'],
        tail: ['all']
      }
    }
  })

  // Emit the request to the socket.
  socket.send(JSON.stringify(requestPayload))
}

/**
 * Sends a request to establish a shell connection to a device's container.
 *
 * @param {Object} socket - The WebSocket instance.
 * @param {String} deviceId - The ID of the device.
 * @param {String} containerId - The ID of the container.
 * @returns {Promise<void>} A promise that resolves when the request is sent.
 */
export const requestShellConnection = async (socket, deviceId, containerId) => {
  const device = await getDeviceDetails(deviceId)

  const containersStore = useDeviceContainersStore()
  const containerExecId = await containersStore.getContainerExecId(deviceId, containerId)

  // Construct request payload.
  let requestPayload = getRequestBasePayload(device)
  requestPayload.payload[0].agents.push({
    ip: device.ip,
    method: 'POST',
    path: {
      apiVersion: DOCKER_API_VERSION,
      resource: 'exec',
      resourceId: containerExecId,
      action: 'start'
    },
    payload: {
      Detach: false,
      Tty: true,
      ConsoleSize: [200, 80]
    }
  })

  // Emit the request to the socket.
  socket.send(JSON.stringify(requestPayload))
}

/**
 * Requests device list stream.
 *
 * @param {Ref<WebSocket} socket – The WebSocket instance.
 * @param {Array} deviceData – The device data to stream.
 * @returns
 */
export const requestDeviceListStreaming = async (socket, deviceData) => {
  if (!socket?.value) {
    logger.error({
      msg: 'WebSocket connection not established, cannot request device list stream.'
    })
    return
  }

  if (!deviceData?.length) {
    logger.error({ msg: 'Device data not provided, cannot request device list stream.' })
    return
  }

  // Create a payload for the WS server containing the devices grouped by their Postman ID.
  // Whilst not in-use today, this allows a single account to have multiple postmen.
  const devicesGroupedByPostmanId = deviceData.reduce((acc, device) => {
    const { postman_id, ip } = device
    const existingGroup = acc.find((group) => group.postmanId === postman_id)

    if (existingGroup) {
      existingGroup.agents.push({ ip })
    } else {
      acc.push({
        postmanId: postman_id,
        agents: [{ ip }]
      })
    }

    return acc
  }, [])

  // Construct request payload.
  let requestPayload = getRequestBasePayload(WEBSOCKET_STREAMS.LIST)
  requestPayload.payload = devicesGroupedByPostmanId

  // Emit the request to the socket.
  socket.value.send(JSON.stringify(requestPayload))
}

/**
 * Parse WebSocket message.
 *
 * @param {Object} message - The parsed WebSocket message object.
 * @returns {Array} The updated device data with online status.
 */
export const parseSocketMessage = (message) => {
  if (!message) {
    logger.error({ msg: 'No WebSocket message provided to parse.' })
    return
  }

  const parsedMessage = JSON.parse(message)

  // Abort message handling if the socket sends a 'done' message.
  if (parsedMessage.message === 'done') {
    return
  }

  // Abort message handling if the socket sends an error message.
  if (parsedMessage.error) {
    const { error } = parsedMessage
    throw new Error(`WebSocket error: ${error}`)
  }

  // Check for device data in the response, if none are present we assume that no devices are online and we can safely
  // abort the message processing.
  if (!parsedMessage.agents?.length) {
    logger.debug('No devices online.')
    return
  }

  // Return list of ip addresses from the parsed message.
  return parsedMessage.agents.map((agent) => agent.ip)
}
