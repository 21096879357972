import getApiClient from '@/clients/apiClient'
import { DEVICE_ENDPOINT } from '@/constants/ApiEndpoints'

const useDeviceService = () => {
  /**
   * Add Device
   *
   * @returns {Object}
   */
  const addDevice = async (deviceData) => {
    const { deviceName, securityGroupId, securityPolicyId } = deviceData

    // @TODO: Add securityGroupId and securityPolicyId to the required parameters once supported.
    if (!deviceName) {
      throw new Error('Missing required parameters')
    }

    const ApiClient = await getApiClient()
    const response = await ApiClient.post(DEVICE_ENDPOINT, {
      deviceName,
      securityGroupId,
      securityPolicyId
    })

    return response.data
  }

  /**
   * Get devices
   *
   * @param {Number} [page=1] - The page number to fetch.
   * @returns {Object}
   */
  const getDevices = async (page = 1) => {
    const ApiClient = await getApiClient()
    const response = await ApiClient.get(DEVICE_ENDPOINT, {
      params: {
        page
      }
    })

    return response.data.data
  }

  return {
    addDevice,
    getDevices
  }
}

export default useDeviceService
