import { useAuthStore } from '../stores/auth'
import { ONBOARDING_PATH } from '../constants/Routes'
import logger from '@/logger'
import { ACCOUNT_PROVISIONING_STATUS } from '@/constants/Account'

/**
 * Account Onboarding Guard Middleware.
 *
 * Verifies that the user has completed onboarding before allowing access to the route. If the user has not completed
 * onboarding, the user is redirected to the onboarding page.
 *
 * @param {Object} to The Vue-Router target route object.
 * @param {Object} from The Vue-Router source route object.
 * @param {Function} next The Vue-Router next() function.
 * @returns {void|Object} The route to redirect to if the user is not authenticated.
 */
export const accountOnboardingGuardMiddleware = async (to, from, next) => {
  const authStore = useAuthStore()

  if (!authStore.account.id) {
    await authStore.loadAccountData()
  }

  if (authStore.account?.status !== ACCOUNT_PROVISIONING_STATUS.COMPLETED) {
    logger.debug('User has not completed onboarding. Redirecting to onboarding page.')
    return next(ONBOARDING_PATH)
  }

  next()
}
