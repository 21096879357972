import { useQuery } from '@tanstack/vue-query'
import useDeviceService from '@/services/device.service'
import { DEVICES_QUERY_KEY } from '@/constants/QueryKeys'

/**
 * Devices Query.
 *
 * @param {Object} [queryOptions] - Optional query options to customize the behavior of the query.
 * @returns {Object} - Returns the result of the `useQuery` hook, including the account data and query status.
 */
const useDevicesQuery = (queryOptions = undefined) => {
  const { getDevices } = useDeviceService()

  return useQuery({
    queryKey: [DEVICES_QUERY_KEY],
    queryFn: async () => {
      return getDevices()
    },
    ...queryOptions
  })
}

export default useDevicesQuery
