import { BADGE_TYPES } from '@/components/Badge/Badge.vue'
import { DEVICE_STATUSES } from '@/constants/Devices'

/**
 * Get device statuss.
 *
 * This function returns the status of a device based on its online status. The online property is injected into the
 * device payload by app when the websocket connection is established and the first list of devices is received. Whilst
 * that is happening, the online property is undefined, so we return a pending status.
 *
 * @TODO: Extend to include the last seen timestamp and determine if the device is online based on that once that
 * property is supported via the webapp.
 *
 * @param {Object} device - The device object to get the status for.
 * @returns {Object} The device status object containing the status and badge color.
 */
const getDeviceStatus = (device) => {
  if (device.online === undefined) {
    return {
      status: DEVICE_STATUSES.PENDING,
      color: BADGE_TYPES.DEFAULT
    }
  }

  if (device.online) {
    return {
      status: DEVICE_STATUSES.ONLINE,
      color: BADGE_TYPES.SUCCESS
    }
  }

  return {
    status: DEVICE_STATUSES.OFFLINE,
    color: BADGE_TYPES.ERROR
  }
}

export default getDeviceStatus
