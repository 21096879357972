import { defineStore, acceptHMRUpdate } from 'pinia'

import {
  getDeviceContainers,
  getDeviceContainerStats,
  getDeviceContainerExecId
} from '@/services/deviceRelayService'

import { DOCKER_SHORT_ID_LENGTH } from '@/constants/Docker'

import logger from '@/logger'

/**
 * Device Containers Store
 *
 * This store is used to manage device container data. Once fetched via the MoT API, the data is processed and
 * stored in this store for use in the UI. A user can manually refresh the data, which will trigger a new fetch from the
 * server.
 */
export const useDeviceContainersStore = defineStore({
  id: 'deviceContainers',
  state: () => ({
    containers: {},
    containerStatsFetched: false
  }),
  getters: {
    byProject: (state) => {
      return (deviceId) => {
        const result = {
          projects: [],
          individual: []
        }

        const deviceContainers = state.containers[deviceId] ? state.containers[deviceId].data : []

        for (let key in deviceContainers) {
          const { Id, Names, Image, State, Status, Labels } = deviceContainers[key]
          const projectName = Labels?.['com.docker.compose.project']

          if (projectName) {
            let project = result.projects.find((p) => p.name === projectName)
            if (!project) {
              project = { name: projectName, count: 0, containers: [] }
              result.projects.push(project)
            }
            project.containers.push({
              id: Id,
              shortId: Id.slice(0, DOCKER_SHORT_ID_LENGTH),
              name: Names[0].startsWith('/') ? Names[0].substring(1) : Names[0],
              image: Image,
              state: State,
              status: Status
            })
            project.count = project.containers.length
          } else {
            result.individual.push({
              id: Id,
              shortId: Id.slice(0, DOCKER_SHORT_ID_LENGTH),
              name: Names[0].startsWith('/') ? Names[0].substring(1) : Names[0],
              image: Image,
              state: State,
              status: Status
            })
          }
        }

        return result
      }
    },

    /**
     * Get the aggregated CPU stats for a specific device.
     *
     * @param {object} state - The current state of the store.
     * @returns {function} A function that takes a device ID and returns the aggregated CPU stats.
     */
    aggregatedCpuStats: (state) => {
      return (deviceId) => {
        const deviceContainers = state.containers[deviceId] ? state.containers[deviceId].data : []
        let totalCpuPercent = 0
        let highestNumberCpus = 0

        for (const container of Object.values(deviceContainers)) {
          const stats = container.Stats

          if (stats) {
            if (
              stats.cpu_stats.cpu_usage.total_usage &&
              stats.precpu_stats.cpu_usage.total_usage &&
              stats.cpu_stats.system_cpu_usage &&
              stats.precpu_stats.system_cpu_usage
            ) {
              // The CPU stats are caculated using the formula from the Docker API documentation:
              // > cpu_delta = cpu_stats.cpu_usage.total_usage - precpu_stats.cpu_usage.total_usage
              // > system_cpu_delta = cpu_stats.system_cpu_usage - precpu_stats.system_cpu_usage
              // > number_cpus = lenght(cpu_stats.cpu_usage.percpu_usage) or cpu_stats.online_cpus
              // > Total CPU usage % = (cpu_delta / system_cpu_delta) * number_cpus * 100.0
              // @see https://docs.docker.com/engine/api/v1.45/#tag/Container/operation/ContainerExport

              const cpuDelta =
                stats.cpu_stats.cpu_usage.total_usage - stats.precpu_stats.cpu_usage.total_usage
              const systemCpuDelta =
                stats.cpu_stats.system_cpu_usage - stats.precpu_stats.system_cpu_usage
              const numberCpus = stats.cpu_stats.cpu_usage.percpu_usage
                ? stats.cpu_stats.cpu_usage.percpu_usage.length
                : stats.cpu_stats.online_cpus || 1 // Default to 1 if undefined

              if (numberCpus > highestNumberCpus) {
                highestNumberCpus = numberCpus
              }

              if (
                !isNaN(cpuDelta) &&
                !isNaN(systemCpuDelta) &&
                systemCpuDelta > 0 &&
                numberCpus > 0
              ) {
                const cpuPercent = (cpuDelta / systemCpuDelta) * numberCpus * 100.0
                totalCpuPercent += cpuPercent
              }
            }
          }
        }

        return {
          used: totalCpuPercent.toFixed(2) + '%',
          max: highestNumberCpus * 100 + '%'
        }
      }
    },

    /**
     * Get the aggregated memory stats for a specific device.
     *
     * @param {object} state - The current state of the store.
     * @returns {function} A function that takes a device ID and returns the aggregated memory stats.
     */
    aggregatedMemoryStats: (state) => {
      return (deviceId) => {
        const deviceContainers = state.containers[deviceId] ? state.containers[deviceId].data : []
        let totalMemoryUsage = 0
        let highestMemoryLimit = 0

        for (const container of Object.values(deviceContainers)) {
          const stats = container.Stats

          if (stats) {
            if (stats.memory_stats.usage && stats.memory_stats.limit) {
              totalMemoryUsage += stats.memory_stats.usage
              if (stats.memory_stats.limit > highestMemoryLimit) {
                highestMemoryLimit = stats.memory_stats.limit
              }
            }
          }
        }

        return {
          used: (totalMemoryUsage / (1024 * 1024)).toFixed(2) + 'MB',
          max: (highestMemoryLimit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
        }
      }
    },

    /**
     * Get the aggregated container stats for a specific device.
     *
     * @param {object} state - The current state of the store.
     * @returns {function} A function that takes a device ID and returns the aggregated container stats.
     */
    aggregatedContainerStats: (state) => {
      return (deviceId) => {
        const deviceContainers = state.containers[deviceId] ? state.containers[deviceId].data : []
        let totalContainers = 0
        let runningContainers = 0

        for (const container of Object.values(deviceContainers)) {
          totalContainers++
          if (container.State === 'running') {
            runningContainers++
          }
        }

        return {
          running: runningContainers,
          total: totalContainers
        }
      }
    }
  },
  actions: {
    /**
     * Get device containers
     *
     * @param {string} deviceId – The device ID.
     * @returns {Promise<void>}
     */
    async getContainers(deviceId, forceRefresh = false) {
      try {
        const containerDataAvailable = Object.keys(this.containers[deviceId]?.data || {}).length > 0

        if (containerDataAvailable && !forceRefresh) {
          logger.debug('Using cached data for device containers.')
          return
        }

        this.containers[deviceId] = this.containers[deviceId] || {}
        this.containers[deviceId].loading = true

        // Fetch the containers for the device.
        const containers = await getDeviceContainers(deviceId)

        // Store the list of containers with their IDs as keys.
        this.$patch((state) => {
          state.containers[deviceId] = {
            loading: false,
            fetchedAt: new Date(),
            error: null,
            data: containers.reduce((acc, container) => {
              acc[container.Id] = container
              return acc
            }, {})
          }
        })

        logger.debug(`Containers store data set for device ${deviceId}.`)
      } catch (err) {
        console.error('error 1', err)
        this.containers[deviceId].error = err
        this.containers[deviceId].loading = false

        logger.error({ msg: `Failed to fetch data for device ${deviceId}`, error: err })
      } finally {
        this.containers[deviceId].loading = false
      }
    },

    /**
     * Fetch container stats
     *
     * @param {string} deviceId – The device ID.
     * @returns {Promise<void>}
     */
    async getContainerStats(deviceId) {
      try {
        const containerDataAvailable = Object.keys(this.containers[deviceId]?.data || {}).length > 0
        const containerStatsAvailable = !!this.containerStatsFetched

        if (containerDataAvailable && containerStatsAvailable) {
          logger.debug('Using cached data for device container stats.')
          return
        }

        if (!containerDataAvailable) {
          logger.debug(
            `Device ${deviceId} has no containers to fetch stats for, fetching containers first.`
          )

          await this.getContainers(deviceId)
        }

        const containerIds = Object.keys(this.containers[deviceId].data)
        const containerStats = await getDeviceContainerStats(deviceId, containerIds)

        // Store the stats inside each container object.
        for (let item of containerStats) {
          if (this.containers[deviceId].data[item.containerId]) {
            this.containers[deviceId].data[item.containerId].Stats = item
          }
        }
        this.containerStatsFetched = true
        logger.debug(`Container stats updated for device ${deviceId}.`)
      } catch (err) {
        logger.error({ msg: `Failed to fetch container stats for device ${deviceId}`, error: err })
        this.containerStatsFetched = false
      }
    },

    /**
     * Get the exec ID for a container.
     *
     * @param {string} deviceId - The ID of the device.
     * @param {string} containerId - The ID of the container.
     * @returns {Promise<string>} A promise that resolves with the exec ID.
     */
    async getContainerExecId(deviceId, containerId) {
      try {
        // Check if the container has an exec ID.
        const existingExecId = this.containers[deviceId]?.data[containerId]?.ExecID
        if (existingExecId) {
          logger.debug(`Using cached exec ID for container ${containerId}`)
        }

        // Otherwise, fetch the exec ID and store it.
        const execId = await getDeviceContainerExecId(deviceId, containerId)

        this.$patch((state) => {
          state.containers[deviceId].data[containerId].ExecID = execId
        })

        return execId
      } catch (err) {
        logger.error({ msg: `Failed to fetch exec ID for container ${containerId}`, error: err })
      }
    }
  }
})

// Support HMR
// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#hmr-hot-module-replacement
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceContainersStore, import.meta.hot))
}
