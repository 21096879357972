/**
 * The possible device statuses.
 *
 * @constant {Object} DEVICE_STATUSES
 * @property {String} ONLINE - Online status, when the device is returned from WebSocket.
 * @property {String} OFFLINE - Offline status, when the device is not returned from the WebSocket.
 * @property {String} PENDING - Pending status, when the device has been added to the database but was never seen.
 */
export const DEVICE_STATUSES = Object.freeze({
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  PENDING: 'Pending'
})
