/**
 * Query keys
 */
export const ACCOUNT_QUERY_KEY = 'account'
export const DEVICES_QUERY_KEY = 'devices'

/**
 * Mutation keys
 */
export const ACCOUNT_USER_UPDATE_MUTATION_KEY = 'account-user-update'
export const ACCOUNT_PROVISIONING_MUTATION_KEY = 'account-provisioning'
export const DEVICE_ADD_MUTATION_KEY = 'device-add'
export const SIGN_UP_MUTATION_KEY = 'sign-up'
