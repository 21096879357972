import mitt from 'mitt'
import { useWebSocket as useWs } from '@vueuse/core'
import { useAuthStore } from '@/stores/auth'
import logger from '@/logger'
import {
  WEBSOCKET_ENDPOINT,
  WEBSOCKET_AUTH_PREFIX,
  WEBSOCKET_PROTOCOL,
  WEBSOCKET_MESSAGE_EVENT_KEY
} from '@/constants/WebSocket'

/**
 * Constructs the WebSocket URL using the account ID.
 *
 * @returns {string} The full WebSocket URL.
 */
const getWebSocketUrl = () => {
  const baseUrl = import.meta.env.VITE_MOT_WS_BASE_URL
  return `${baseUrl}${WEBSOCKET_ENDPOINT}`
}

/**
 * Encodes the token and constructs an array of WebSocket protocols.
 *
 * @param {string} token - The authentication token to be encoded.
 * @returns {string[]} An array containing the WebSocket protocol and the encoded authentication token.
 */
const getWebSocketProtocols = (token) => {
  return [WEBSOCKET_PROTOCOL, `${WEBSOCKET_AUTH_PREFIX}${token}`]
}

/**
 * Composable function to create a WebSocket connection with authentication.
 *
 * @params {boolean} emitMessageEvents - Whether to emit message events.
 * @returns {Object} An object containing the WebSocket connection status, data, send, open, and close functions.
 */
const useWebSocket = (emitMessageEvents = false) => {
  let emitter

  const authStore = useAuthStore()

  const socketUrl = getWebSocketUrl()
  const socketProtocols = getWebSocketProtocols(authStore.session.access_token)

  if (emitMessageEvents) {
    emitter = mitt()
  }

  return new Promise((resolve, reject) => {
    const { status, data, send, open, close, ws } = useWs(socketUrl, {
      protocols: socketProtocols,
      onConnected: () => {
        if (emitMessageEvents) {
          resolve({ status, data, send, open, close, ws, emitter })
        } else {
          resolve({ status, data, send, open, close, ws })
        }
      },
      onDisconnected: () => {
        if (emitMessageEvents) {
          emitter.off(WEBSOCKET_MESSAGE_EVENT_KEY)
        }

        reject(new Error('WebSocket connection closed.'))
      },
      onError: (ws, error) => {
        logger.error({ msg: 'WebSocket error.', error })
        reject(error)
      },
      onMessage: (ws, message) => {
        if (emitMessageEvents) {
          emitter.emit(WEBSOCKET_MESSAGE_EVENT_KEY, message?.data)
        }
      }
    })
  })
}

export default useWebSocket
