import { defineStore, acceptHMRUpdate } from 'pinia'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import logger from '../logger'
import getEnvUrl from '../utils/getEnvUrl'
import AuthClient from '../clients/authClient'

import { FREE_PLAN_DATA } from '../constants/BillingData'
import { LOGIN_ROUTE_PATH, ONBOARDING_PATH } from '../constants/Routes'
import { TABLES } from '../constants/Database'

/**
 * Auth Store
 *
 * This store is used to manage the user's session and authentication state via Pinia.
 */
export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    session: null,
    user: {
      firstName: null,
      lastName: null
    },
    account: {
      id: null,
      role: null,
      subscription: null
    }
  }),
  getters: {
    accountDeviceAllowance: (state) => {
      // If the account has no subscription, return the free plan allowance.
      if (!state.account.subscription) return FREE_PLAN_DATA.metadata.maxDevices

      // Otherwise, return the subscription allowance value stored in the product metadata.
      return state.account.subscription.product.metadata.maxDevices
    }
  },
  actions: {
    /**
     * Mount the auth session.
     *
     * This method will fetch the user's session and subscribe the store to the auth state change event to update the
     * store. This method should be called once when the application is mounted in ./src/App.vue.
     *
     * Should the user's session not be found or an unexpected error happen, the user will be redirected to the login
     * page in attempt to provide a better user experience.
     *
     * @returns {void}
     */
    async mountAuthSession() {
      try {
        // Fetch the user's session and update the store with both session and account information.
        const { data, error } = await AuthClient.auth.getSession()
        if (error) throw error

        this.session = data.session
        logger.debug(`Session mounted successfully.`)

        // Subscribe the store to the auth state change event to update the store with the session information.
        AuthClient.auth.onAuthStateChange((_, _session) => {
          this.session = _session
        })
      } catch (error) {
        logger.error({
          msg: `Couldn't mount auth sessions due to an error: ${error?.message || 'Unkown error'}.`,
          error
        })

        this.$router.push(LOGIN_ROUTE_PATH)
      }
    },

    /**
     * Refresh the user's session.
     *
     * This method will refresh the user's session and update the store. This should only be invoked in very special
     * circumstances such as the onboarding flow where we update session relevant data in the database.
     *
     * @returns {void}
     */
    async refreshSession() {
      try {
        const { data, error } = await AuthClient.auth.refreshSession()

        if (error) throw error

        this.session = data.session

        logger.debug(`Session refreshed.`)
      } catch (error) {
        logger.error({
          msg: `Couldn't refresh auth sessions due to an error: ${
            error?.message || 'Unkown error'
          }.`,
          error
        })
      }
    },

    /**
     * Load the user's session data.
     *
     * This method will retrieve the user's account data from the database and update the store. This method is invoked
     * alongside the auth session mount in src/App.vue to ensure the account data is available to the application.
     *
     * @param {boolean} force Whether to force the account data to be reloaded or not.
     * @returns {void}
     */
    async loadAccountData(force = false) {
      if (!force && this.account.id) return

      try {
        const { id: userId, app_metadata: appMetadata } = this.session.user

        // Retrieve the data for the current session. Using a JOIN statement, we can retrieve the user profile, the
        // corresponding account and the subscription data in a single query.
        const { data, error } = await AuthClient.from(TABLES.USERS)
          .select(
            `
              user_id, 
              first_name, 
              last_name, 
              account_users (
                  account_id,
                  role,
                  accounts (
                      provisioning_status,
                      subscription_records!subscription_records_account_id_fkey (
                          *,
                          product:subscription_products!subscription_records_product_id_fkey (*)
                      )
                  )
              )
          `
          )
          .eq('user_id', userId)
          .single()

        if (error) throw error

        // Check that the account ID matches the value in the session.
        if (data?.account_users[0]?.account_id !== appMetadata.mot_account_id) {
          throw new Error('Account ID mismatch.')
        }

        // Prepare data in the expected format for the store.
        // Fill the user data.
        this.user = {
          firstName: data.first_name,
          lastName: data.last_name
        }

        // If available, fill the account data.
        if (data?.account_users?.length) {
          this.account = {
            id: data?.account_users[0]?.account_id,
            role: data?.account_users[0]?.role,
            status: data?.account_users[0]?.accounts?.provisioning_status
          }
        }

        // If the account has a subscription, fill the subscription data and fetch the latest product metadata.
        if (
          data?.account_users?.length &&
          data?.account_users[0]?.accounts?.subscription_records?.length
        ) {
          this.account.subscription = camelcaseKeys(
            data?.account_users[0]?.accounts?.subscription_records[0]
          )
        }

        logger.debug(`Retrieved account data.`)
      } catch (error) {
        logger.error({
          msg: `Could not retrieve account data: ${error.msg || 'Unknown error.'}`,
          error
        })

        this.signOut()
      }
    },

    /**
     * Sign-up with email.
     *
     * Leveraging Supabase, this method will create a new user account and send an email to the customer with a magic
     * link to confirm their email address and proceed to the onboarding flow.
     *
     * @param {string} email The customer's email address to sign-up with.
     * @returns {Object} The result of the sign-up attempt.
     */
    async signUpWithEmail(email) {
      try {
        const { error } = await AuthClient.auth.signInWithOtp({
          email,
          options: {
            shouldCreateUser: true,
            emailRedirectTo: getEnvUrl(ONBOARDING_PATH)
          }
        })

        if (error) throw error

        logger.debug(`Sign-up request for ${email} processed successfully.`)

        return {
          success: true
        }
      } catch (error) {
        logger.error({
          msg: `Couldn't process sign-up due to an error: ${error?.message || 'Unkown error'}.`,
          error
        })

        // TODO: Improve error handling with custom error messages based on the Supabase error response.
        return {
          success: false,
          error: error instanceof Error ? error.message : error
        }
      }
    },

    /**
     * Sign-in with email.
     *
     * Leveraging Supabase, this method will send an email to the customer with a magic link to sign-in. User account
     * creation is explicitely disabled to force users to use the sign-up flow.
     *
     * @param {string} email The customer's email address to sign-in with.
     * @returns {Object} The result of the sign-in attempt.
     */
    async signInWithEmail(email) {
      try {
        const { error } = await AuthClient.auth.signInWithOtp({
          email,
          options: {
            shouldCreateUser: false,
            emailRedirectTo: getEnvUrl()
          }
        })

        if (error) throw error

        logger.debug(`Sign-in request for ${email} processed successfully.`)

        return {
          success: true
        }
      } catch (error) {
        logger.error({
          msg: `Couldn't process sign-in due to an error: ${error?.message || 'Unkown error'}.`,
          error
        })

        // TODO: Improve error handling with custom error messages based on the Supabase error response.
        return {
          success: false,
          error: error instanceof Error ? error.message : error
        }
      }
    },

    /**
     * Sign-out the user.
     *
     * Signs-out the user and redirects them to the login page by default. Alternatively, the redirect can be disabled
     * or a custom redirect target can be specified.
     *
     * @param {string} redirectPath The route to redirect to after sign-out. Defaults to the login page.
     * @returns {Promise<void>}
     */
    async signOut(redirectPath = LOGIN_ROUTE_PATH) {
      try {
        const { error } = await AuthClient.auth.signOut()

        if (error) throw error

        if (redirectPath) {
          this.$router.push({ path: redirectPath })
        }
      } catch (error) {
        logger.error({
          msg: `Could not process sign-out: ${error.message || 'Unknown error.'}`,
          error
        })
      }
    },

    /**
     * Check if the user is authenticated.
     *
     * Here too leveraging the Supabase client, this method will check if the user is authenticated, update the current
     * store with the user's session information and return a boolean indicating if the user is authenticated or not.
     *
     * @returns {Promise<Boolean>} A boolean indicating if the user is authenticated or not.
     */
    async isAuthenticated() {
      try {
        const { data, error } = await AuthClient.auth.getSession()
        if (error) throw error

        if (data.session) {
          logger.debug(`User is authenticated.`)
          return true
        }

        logger.debug(`User is not authenticated, login required.`)
        return false
      } catch (error) {
        logger.error({
          msg: `Could not check authentication status: ${error.message || 'Unknown error.'}`,
          error
        })

        this.signOut()

        return false
      }
    },

    /**
     * Update the user's profile data.
     *
     * This method will update the user's profile data in the database and update the store. This method should only be
     * needed to be invoked from the Onboarding flow and the user preferences screen.
     *
     * @param {Object} profileData The user's profile data to update.
     * @returns {void}
     */
    async updateUser(profileData) {
      try {
        const { id: userId } = this.session.user

        let queryPayload = {}

        // Map form fields to database columns.
        const mappings = {
          firstname: 'firstName',
          lastname: 'lastName',
          company: 'company',
          marketingConsent: 'marketingConsent'
        }

        // Updated the query payload with the provided data.
        for (let key in mappings) {
          if (profileData[key] || (key === 'marketingConsent' && profileData[key] === false)) {
            queryPayload[mappings[key]] = profileData[key]
          }
        }

        if (!Object.keys(queryPayload).length) {
          throw new Error('No data provided to update user profile.')
        }

        const { data, error } = await AuthClient.from('users')
          .update(snakecaseKeys(queryPayload))
          .eq('user_id', userId)
          .select()
          .single()

        if (error) throw error

        this.user = data
        logger.debug(`Updated account data.`)

        return {
          success: true
        }
      } catch (error) {
        logger.error({ msg: 'Could not update account data', error })

        // TODO: Improve error handling with custom error messages based on the Supabase error response.
        return {
          success: false,
          error: error instanceof Error ? error.message : error
        }
      }
    }
  }
})

// Support HMR
// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#hmr-hot-module-replacement
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
