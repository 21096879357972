import { defineStore, acceptHMRUpdate } from 'pinia'

import { useAuthStore } from './auth'
import logger from '../logger'
import DatabaseClient from '../clients/databaseClient'
import { TABLES } from '../constants/Database'

/**
 * Devices Store
 *
 * This store is used to manage the user's device state via Pinia.
 */
export const useDevicesStore = defineStore({
  id: 'devices',
  state: () => ({
    loading: true,
    devices: [],
    deviceCount: 0
  }),
  actions: {
    /**
     * Fetch device count
     *
     * This method will fetch the total number of devices from the database and update the store with the count. This is
     * used to avoid fetching all device details from the database when the user is viewing the settings pages or places
     * where only the device count is relevant.
     */
    async fetchDeviceCount() {
      try {
        // Get the authenticated user's details from the auth store.
        const authStore = useAuthStore()
        const { account } = authStore

        // Fetch the device count from the database.
        const { error, count } = await DatabaseClient.from(TABLES.DEVICES)
          .select('device_id', {
            count: 'exact',
            head: true
          })
          .eq('account_id', account.id)

        // Throw error if there was an error fetching the device count.
        if (error) throw error

        // Update the store with the device count, default to 0.
        this.deviceCount = count || 0
      } catch (error) {
        logger.error({ msg: 'Failed to fetch device count', error })
      }
    },

    /**
     * Fetch devices
     *
     * @returns {Promise<void>}
     */
    async getDevices(limit = 10) {
      try {
        this.loading = true

        // Get the authenticated user's details from the auth store.
        const authStore = useAuthStore()
        const { account } = authStore

        // Fetch all devices from the database.
        const { data, error } = await DatabaseClient.from(TABLES.DEVICES)
          .select('device_id, name, ip, postman_id, last_seen, updated_at, created_at')
          .eq('account_id', account.id)
          .limit(limit)
          .order('name')

        // Throw error if there was an error fetching the device count.
        if (error) throw error

        // Update the store with the devices data or an empty array.
        this.devices = data || []

        logger.debug('Devices store updated with devices data.')
      } catch (error) {
        logger.error({ msg: 'Failed to fetch devices', error })
      } finally {
        this.loading = false
      }
    },

    /**
     * Get device by ID
     *
     * @param {string} deviceId – The device ID to fetch.
     */
    async getDeviceById(deviceId) {
      try {
        // Check if the device is already in the store.
        const device = this.devices.find((device) => device.device_id === deviceId)

        // If so, return the device record.
        if (device) {
          return device
        }

        // Otherwise, fetch it from the database.
        this.loading = true

        // Get the authenticated user's details from the auth store.
        const authStore = useAuthStore()
        const { account } = authStore

        // Fetch all devices from the database.
        const { data, error } = await DatabaseClient.from(TABLES.DEVICES)
          .select('*')
          .eq('account_id', account.id)
          .eq('device_id', deviceId)
          .maybeSingle()

        // Throw error if there was an error fetching the device count.
        if (error) throw error

        // Push the device to the store.
        this.devices.push(data)

        logger.debug(`Devices store updated with device ${deviceId} data.`)

        // Return the device data.
        return data
      } catch (error) {
        logger.error({ msg: `Failed to fetch data for device ${deviceId}`, error })
      } finally {
        this.loading = false
      }
    },

    /**
     * Refresh devices
     *
     * Compare to getDevices, this method will only fetch the devices that are not in the store and add them to the
     * store. This is used to avoid fetching all device details again.
     *
     * @returns {void}
     */
    async refreshDevices() {
      try {
        // Get the authenticated user's details from the auth store.
        const authStore = useAuthStore()
        const { account } = authStore

        // Fetch all devices from the database.
        const { data, error } = await DatabaseClient.from(TABLES.DEVICES)
          .select('device_id, name, ip, postman_id, last_seen')
          .eq('account_id', account.id)
          .not('device_id', 'in', `(${this.devices.map((device) => device.device_id).join(',')})`)
          .order('name')

        // Throw error if there was an error fetching the device count.
        if (error) throw error

        // Push the new devices to the store.
        this.devices.push(...data)

        logger.debug('Devices store refreshed with devices data.')
      } catch (error) {
        logger.error({ msg: 'Failed to refresh devices', error })
      }
    },

    /**
     * Update devices last seen timestamp
     *
     * This method should be exclusively used by the WebSocket service to update the last seen timestamp.
     *
     * @TODO: Refactor to a more general "updateDevices" method that accepts the key and value to update.
     *
     * @param {Array} devices - Array of devices to update.
     * @returns {void}
     */
    updateDevicesVisibilityStatus(websocketDevices) {
      try {
        // Get the current timestamp in ISO format.
        const now = new Date().toISOString()

        // For all devices that were informed in the payload, update the last_seen and online values. For all other
        // devices in the store but not in the payload, set the online value to false.
        this.devices.forEach((device) => {
          // Remove the subnet from the IP address.
          // const deviceIp = device.ip.split('/')[0]

          // Find the device in the payload.
          const matchingDevice = websocketDevices.find((websocketDevice) => {
            // Remove the subnet from the IP address.
            const websocketDeviceIp = websocketDevice.ip.split('/')[0]

            // Return the device if the IP address matches.
            return websocketDeviceIp === device.ip
          })

          // If the device is in the payload, update the last_seen value.
          if (matchingDevice) {
            device.last_seen = now
            device.online = true
          } else {
            device.online = false
          }
        })
      } catch (error) {
        logger.error({ msg: 'Failed to update device visibility status', error })
      }
    }
  }
})

// Support HMR
// https://pinia.vuejs.org/cookbook/hot-module-replacement.html#hmr-hot-module-replacement
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDevicesStore, import.meta.hot))
}
