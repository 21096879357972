import AuthClient from '@/clients/authClient'
import logger from '@/logger'
import getEnvUrl from '@/utils/getEnvUrl'
import { ONBOARDING_PATH } from '@/constants/Routes'

const useAuthService = () => {
  const getAccessToken = async () => {
    const { data, error } = await AuthClient.auth.getSession()

    if (error) throw error

    return data.session.access_token
  }

  const signUp = async (data) => {
    const { email } = data

    const { error } = await AuthClient.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: true,
        emailRedirectTo: getEnvUrl(ONBOARDING_PATH)
      }
    })

    if (error) throw error

    logger.debug(`Sign-up request for ${email} processed successfully.`)

    return {
      success: true
    }
  }

  return {
    signUp,
    getAccessToken
  }
}

export default useAuthService
