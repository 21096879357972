/**
 * WebSocket Constants
 *
 * @constant {string} WEBSOCKET_ENDPOINT – The WebSocket endpoint.
 * @constant {string} WEBSOCKET_PROTOCOL – The WebSocket protocol to use.
 * @constant {string} WEBSOCKET_AUTH_PREFIX – The prefix to use for the WebSocket authentication header.
 * @constant {Object} WEBSOCKET_ACTIONS – The actions that can be performed on the WebSocket server.
 * @constant {Object} WEBSOCKET_STREAMS – The streams that can be subscribed to on the WebSocket server.
 * @constant {number} WEBSOCKET_RELAY_REFRESH_TIMEOUT – The timeout in ms to wait before allowing a manual refresh.
 * @constant {string} WEBSOCKET_MESSAGE_EVENT_KEY – The key to use for the WebSocket message event.
 */
export const WEBSOCKET_ENDPOINT = '/ws'

export const WEBSOCKET_PROTOCOL = 'v1.meshofthings.io'

export const WEBSOCKET_AUTH_PREFIX = 'jwt.bearer.authorization.meshofthings.io.'

export const WEBSOCKET_ACTIONS = Object.freeze({
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe'
})

export const WEBSOCKET_STREAMS = Object.freeze({
  LIST: 'list',
  RELAY: 'relay'
})

export const WEBSOCKET_RELAY_REFRESH_TIMEOUT = 5000

export const WEBSOCKET_MESSAGE_EVENT_KEY = 'socket-message'
