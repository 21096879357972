<template>
  <div class="mb-8">
    <template v-if="isLoading"></template>

    <template v-else-if="!isLoading && !devices.length">
      <EmptyState title="No devices" description="Add a new device to get started.">
        <template #icon>
          <ServerIcon class="stroke-1" />
        </template>
      </EmptyState>
    </template>

    <template v-else>
      <DataTable :columns="columns" :data="devices" />
    </template>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import { ServerIcon } from '@heroicons/vue/24/outline'
import EmptyState from '@/components/EmptyState/EmptyState.vue'
import { columns } from './columns'
import DataTable from './DataTable.vue'
import useDevicesQuery from '@/composables/queries/useDevicesQuery'
import useWebSocket from '@/composables/useWebSocket'
import { requestDeviceListStreaming, parseSocketMessage } from '@/services/deviceSocketService'
import { WEBSOCKET_MESSAGE_EVENT_KEY } from '@/constants/WebSocket'
import { DEVICES_QUERY_KEY } from '@/constants/QueryKeys'

const socket = ref(null)

const queryClient = useQueryClient()
const { isLoading, data: devices } = useDevicesQuery()

const handleSocketMessage = (message) => {
  const deviceIps = parseSocketMessage(message)

  // If no device IPs are returned, mark all devices as offline.
  if (!deviceIps) {
    queryClient.setQueryData([DEVICES_QUERY_KEY], (data) =>
      data.map((device) => ({
        ...device,
        online: false
      }))
    )

    return
  }

  // Update the device list with the status of the devices.
  queryClient.setQueryData([DEVICES_QUERY_KEY], (data) =>
    data.map((device) => ({
      ...device,
      online: !!deviceIps.includes(device.ip)
    }))
  )
}

watch(
  devices,
  async (deviceData) => {
    // Close socket if no devices are listed.
    if (!deviceData || !deviceData?.length) {
      socket?.value?.close()
      return
    }

    // Otherwise, open a new socket connection if it doesn't exist.
    if (!socket.value) {
      socket.value = await useWebSocket(true)
      socket.value.emitter.on(WEBSOCKET_MESSAGE_EVENT_KEY, handleSocketMessage)
    }

    // And emit the device list stream request.
    requestDeviceListStreaming(socket, deviceData)
  },
  { immediate: true }
)

onMounted(() => {
  // webSocketService.value = new WebSocketService(WEBSOCKET_STREAMS.LIST)
  // webSocketService.value.initialize()
})

onUnmounted(() => {
  // Close any potential open socket connections.
  socket?.value?.close()
})
</script>
