import axios from 'axios'

import useAuthService from '@/services/auth.service'

/**
 * API Client
 *
 * The Axios HTTP client used to make requests to the backend API endpoints.
 */
const getApiClient = async () => {
  const baseURL = import.meta.env.VITE_BACKEND_API_BASE_URL
  const { getAccessToken } = useAuthService()
  const accessToken = await getAccessToken()

  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export default getApiClient
