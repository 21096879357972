/**
 * Docker Constants
 *
 * @constant DOCKER_CONTAINER_STATES - The possible states of a Docker container.
 * @constant DOCKER_SHORT_ID_LENGTH - The length of a Docker container ID.
 */

export const DOCKER_CONTAINER_STATES = Object.freeze({
  CREATED: 'created',
  RUNNING: 'running',
  PAUSED: 'paused',
  RESTARTING: 'restarting',
  EXITED: 'exited',
  DEAD: 'dead'
})

export const DOCKER_SHORT_ID_LENGTH = 12

export const DOCKER_API_VERSION = 'v1.45'
